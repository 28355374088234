import React, { useMemo, useState } from "react";
import { graphql, navigate } from "gatsby";
import { PageLayout, SEO } from "@components/page-layout";
import {
  BlogArticleBrowseCategories,
  BlogArticleCard,
  BlogArticleCategory,
  BlogArticleCategories,
  // BlogArticleRelatedArticles,
  BlogCategoryHeader,
  BlogSubscribe,
  FollowICash,
  Pagination,
} from "@brainfinance/icash-component-library";
import { Products } from "@containers/products";

import defaultImg from "../images/default.png";

const ARTICLES_PER_PAGE = 9;

const BlogCategoryTemplate = (props: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { articles, allCategories }: any = props.data;

  const pageCategory =
    articles.nodes.length > 0
      ? articles.nodes[0].categories?.find?.((category: any) => category?.slug === props.pageContext.slug)
      : undefined;
  const currentPagedArticles = articles.nodes.slice((currentPage - 1) * ARTICLES_PER_PAGE).slice(0, ARTICLES_PER_PAGE);

  const articlesAfterBasis = useMemo(() => {
    return currentPagedArticles.length % 3 === 1
    ? 'after:basis-[calc(66.666667%_-_30px)]'
    : 'after:basis-[calc(33.333333%_-_30px)]';
  }, [currentPagedArticles]);

  return (
    <PageLayout>
      <SEO title={`Blog | ${pageCategory?.name || props.pageContext.slug}`} />
      <BlogCategoryHeader
        className="mt-[132px] px-[22px]"
        title={pageCategory?.name || props.pageContext.slug}
        description={pageCategory?.description?.description || ""}
        articleCount={articles.nodes.length}
      />
      <div className="flex max-w-[1612px] my-[44px] mx-auto md:px-[11px] md:mx-0 md:mt-0 md:mb-[55px] sm:px-[22px]">
        <FollowICash
          className="mr-[73px] md:hidden"
          onFacebookFollow={() => console.log("share article on facebook")}
          onTwitterFollow={() => console.log("share article on twitter")}
          onLinkedInFollow={() => console.log("share article on linkedin")}
        />
        <div className="flex flex-col mr-[153px] md:mr-0">
          <div className={"flex flex-wrap justify-around mb-[44px] md:mb-[11px] after:content-[''] " + articlesAfterBasis}>
            {currentPagedArticles.map((node: any) => (
              <BlogArticleCard
                key={node.id}
                title={node.h1}
                description={node.metaDescription}
                author={node.author?.name}
                authorImgSrc={node.author?.picture?.gatsbyImageData.images.fallback?.src || defaultImg}
                date={new Date(node.publicationDate)}
                imgSrc={node.mainImage?.gatsbyImageData.images.fallback?.src}
                onClick={() => navigate(`/blog/${node.slug}/`)}
                onAuthorClick={() => navigate(`/blog/${node.author?.slug}/`)}
              >
                <BlogArticleCategories>
                  {node.categories?.map((category: any) => (
                    <BlogArticleCategory
                      key={category?.id}
                      category={category?.name}
                      onClick={() => navigate(`/blog/${category?.slug}/`)}
                    />
                  ))}
                </BlogArticleCategories>
              </BlogArticleCard>
            ))}
          </div>
          {articles.nodes.length > 10 && (
            <div className="mx-auto">
              <Pagination
                total={articles.nodes.length}
                currentPage={currentPage}
                countPerPage={ARTICLES_PER_PAGE}
                onPageNumberClick={(pageNumber: number) => setCurrentPage(pageNumber)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mb-[66px] max-w-[880px] mx-auto px-[14px]">
        <BlogArticleBrowseCategories>
          {allCategories.nodes.map((category: any) => (
            <BlogArticleCategory
              key={category.slug}
              category={category.name}
              hasArrow
              onClick={() => navigate(`/blog/${category.slug}/`)}
            />
          ))}
        </BlogArticleBrowseCategories>
      </div>
      <div className="mb-[44px] mx-auto max-w-[1320px] md:mb-[22px]">
        <BlogSubscribe
          className="md:mx-[-1rem] md:!w-[calc(100%+2rem)]"
          handleSubscribe={() => console.log("subscribed!")}
        >
          <span>{"Subscribe to our "}</span>
          <span className="text-brand-green">newsletter</span>
        </BlogSubscribe>
      </div>
      <div className="mb-[44px]">
        <Products />
      </div>
    </PageLayout>
  );
};

export default BlogCategoryTemplate;

export const pageQuery = graphql`
  query CategoryBlogArticleList($slug: String!) {
    articles: allContentfulBlogArticle(filter: { categories: { elemMatch: { slug: { eq: $slug } } } }) {
      pageInfo {
        totalCount
        pageCount
        itemCount
      }
      nodes {
        id
        slug
        categories {
          slug
          name
          description {
            description
          }
        }
        publicationDate
        mainImage {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 400, quality: 70)
          description
        }
        h1
        author {
          slug
          name
          picture {
            gatsbyImageData(width: 60, quality: 100)
          }
        }
        article {
          raw
        }
        metaDescription
      }
    }

    allCategories: allContentfulBlogCategory {
      nodes {
        name
        slug
      }
    }
  }
`;
