// Images
import iconInstant from "@images/products/ic-instant-loans-xl.svg";
import iconPersonal from "@images/products/ic-personal-loans-xl.svg";
import iconCreditBuilder from "@images/products/ic-credit-builder-loans-xl.svg";
import iconCreditScore from "@images/products/ic-credit-monitoring-xl.svg";

export const PRODUCTS = [
  {
    title: "Instant loan",
    description: "Up to $1500 approved in 5 minutes. Funds sent by e-Transfer.",
    src: iconInstant,
    category: "products"
  },
  {
    title: "Personal loan",
    description: "Pay your bills today and extend your repayments over 90 days.",
    src: iconPersonal,
    category: "products"
  },
  {
    title: "Credit builder",
    description: "Use reliable and honest credit building practices.",
    src: iconCreditBuilder,
    category: "products"
  },
  {
    title: "Credit score",
    description: "Description information, which will explain what the tool does.",
    src: iconCreditScore,
    category: "tools"
  }
];