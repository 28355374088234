import * as React from "react";
import {
  ProductsCard,
  ProductsCardList,
  ProductsContainer,
  ProductsHeader
} from "@brainfinance/icash-component-library";
import { PRODUCTS } from "./products-data";

export const Products = (props: React.PropsWithChildren<{}>) => (
  <ProductsContainer>
    <ProductsHeader>{props.children}</ProductsHeader>
    <ProductsCardList>
      <div className="products--card-items">
        {PRODUCTS.map((product, ndx) =>
          product.category !== "tools" ? <ProductsCard key={ndx} {...product} /> : null
        )}
      </div>
      <div className="products--card-tools">
        {PRODUCTS.map((product, ndx) =>
          product.category === "tools" ? <ProductsCard key={ndx} {...product} /> : null
        )}
      </div>
    </ProductsCardList>
  </ProductsContainer>
);

Products.defaultProps = {
  children: (
    <>
      <h2 className="global--page-heading">iCash product and tools <span className="text-interface-300">for you</span></h2>
      <p className="my-[1.5rem] max-w-[720px] mx-auto global--paragraph !text-interface-300">
        iCash is a licensed, direct lender offering quick loans to Canadians. With our 100% online application process,
        you receive your funds within 5 minutes of approval!
      </p>
    </>
  )
};
